import request from '@/utils/request'

// 获取客户列表
export function getClientList(data) {
  return request({
    url: 'Customer/GetList',
    method: 'post',
    data
  })
}

export function exportCustomer(data) {
		return request({
				url: 'Customer/ExportCustomer',
				method: 'post',
				timeout: 0,
				data
		})
}

export function importCustomer(data) {
		return request({
				url: 'Customer/ImportCustomer',
				method: 'post',
				data,
				timeout: 0,
				headers: {
						'Content-Type': 'multipart/form-data'
				}
		})
}

// 修改紧急度
// {
//   "Id": 0, //公司ID
//   "emergencyLevel": 0 //紧急度
// }
export function editUrgencyList(data) {
  return request({
    url: 'Customer/ModifyUrgency',
    method: 'post',
    data
  })
}

// 获取选择合并的客户信息
// [
//   18,
//   19
// ]
export function getMergeClient(data) {
  return request({
    url: 'Customer/GetMergeCustomerData',
    method: 'post',
    data
  })
}

// 确定合并的客户
export function editMergeClient(data) {
  return request({
    url: 'Customer/MergeCompanyCustomer',
    method: 'post',
    data
  })
}

// 获取全部标签和属性
export function getTagAndAttribute(data) {
  return request({
    url: 'Customer/AllDictItemList',
    method: 'post',
    data
  })
}

// 写跟进
export function editFollowUp(data) {
  return request({
    url: 'Customer/AddFollowUp',
    method: 'post',
    data
  })
}

// 获取客户类型和表头满分
export function getClientType(data) {
  return request({
    url: 'Customer/GetSorceAndType',
    method: 'post',
    data
  })
}

//未跟进
export function selectFollow(data) {
  return request({
    url: 'Customer/WaitFollow',
    method: 'post',
    data
  })
}

//未下单
export function selectNoOrder(data) {
  return request({
    url: 'Customer/selectNoOrder',
    method: 'post',
    data
  })
}

//未询价
export function selectInquired(data) {
  return request({
    url: 'Customer/selectInquired',
    method: 'post',
    data
  })
}

//预计下单
export function selectOnOrder(data) {
  return request({
    url: 'Customer/selectOnOrder',
    method: 'post',
    data
  })
}

//预计跟进
export function selectOnFollow(data) {
  return request({
    url: 'Customer/selectOnFollow',
    method: 'post',
    data
  })
}

//删除客户
export function deleteClient(data) {
  return request({
    url: 'Customer/DeleteCustomer',
    method: 'post',
    data
  })
}

//获取分值
export function GetSocresInfo(data) {
  return request({
    url: 'Customer/GetSocresInfo',
    method: 'post',
    data
  })
}


//获取未下单的客户
export function GetNoOrderList(data){
  return request({
    url:"Customer/GetNoOrderList",
    method:"post",
    data
  })
}

//获取未下单的客户
export function GetNoFollowList(data){
  return request({
    url:"Customer/GetNoFollowList",
    method:"post",
    data
  })
}



// 验证客户编码
export function VerificationCusterCode(data) {
	return request({
		url: 'Customer/VerificationCusterCode',
		method: 'post',
		data
	})
}

// 验证客户名称
export function VerificationCusterName(data) {
	return request({
		url: 'Customer/VerificationCusterName',
		method: 'post',
		data
	})
}

//客户详情
export function getClientInfo(data) {
  return request({
    url: 'Customer/GetCustomer',
    method: 'post',
    data
  })
}

//获取询价记录
export function getInquireList(data) {
  return request({
    url: 'Customer/GetFollowByContact',
    method: 'post',
    data
  })
}
//获取客户联系人信息
export function getClientContactInfo(data) {
  return request({
    url: 'Customer/GetContactsInfo',
    method: 'post',
    data
  })
}


//获取跟进方式
export function getFollowUpInfo(params) {
  return request({
    url: 'Customer/getFollowUpInfo',
    method: 'post',
    params
  })
}

// 新增客户
export function newClientInfo(data) {
  return request({
    url: 'Customer/AddCustomer',
    method: 'post',
    data
  })
}
// 编辑客户
export function editClientInfo(data) {
  return request({
    url: 'Customer/EditCustomer',
    method: 'post',
    data
  })
}

//获取隐藏字段
export function GetClickDetails(data) {
  return request({
    url: 'Customer/GetClickDetails',
    method: 'post',
    data
  })
}


//获取客户等级
export function GetCustomerLevel(data) {
  return request({
    url: 'Customer/GetCustomerLevel',
    method: 'post',
    data
  })
}

//新增客户等级
export function AduitCustomerLevel(data) {
  return request({
    url: 'Customer/AduitCustomerLevel',
    method: 'post',
    data
  })
}

// 写跟进
export function editFollowClientInfo(data) {
  return request({
    url: 'Customer/AddFollowUp',
    method: 'post',
    data
  })
}

//上传图片
export function OnUploadPicture(data) {
  return request({
    url: 'Upload/OnUploadPicture',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 更改客户类型
export function changeClientType(data) {
  return request({
    url: 'Customer/UpdateClientType',
    method: 'post',
    data
  })
}
// 更改客户类型
export function changeRateType(data) {
  return request({
    url: 'Customer/ModifyUrgency',
    method: 'post',
    data
  })
}
// 修改客户归属人
export function changeCompanySales(data) {
		return request({
				url: 'Customer/UpdateCompanySales',
				method: 'post',
				data
		})
}

export function getRequired(data) {
  return request({
    url: 'Customer/GetRequired',
    method: 'get',
    data
  })
}

// 审核客户
export function getAuditClientData(data) {
  return request({
    url: 'Customer/GetNotApprovedList',
    method: 'post',
    data
  })
}
export function auditClient(data) {
  return request({
    url: 'Customer/ApprovedClientType',
    method: 'post',
    data
  })
}


export function NoAduitClientType(data) {
  return request({
    url: 'Customer/NoAduitClientType',
    method: 'post',
    data
  })
}

// 获取筛选条件设置
export function GetCustomerFilterSetting(data) {
  return request({
    url: 'SystemInfo/GetCustomerFilterSetting',
    method: 'post',
    data
  })
}

// 保存筛选条件设置
export function SaveFilterSetting(data) {
  return request({
    url: 'SystemInfo/SaveFilterSetting',
    method: 'post',
    data
  })
}

export function saveUserColumn(data) {
		return request({
				url: 'Customer/SaveUserColumn',
				method: 'post',
				data
		})
}

export function getUserColumn(data) {
		return request({
				url: 'Customer/GetUserColumns',
				method: 'post',
				data
		})
}

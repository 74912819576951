const defaultColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
    valueFormatter: ({ node: { rowIndex } }) => {
      return rowIndex + 1;
    }
  },
  {
    field: 'SetType',
    headerName: '分配方式',
    width: 70,
    cellClass: 't-c',
    valueFormatter: ({ data: { SetType } }) => {
			// 分配时间段.  周: week, 月：month， 季度: season
			let ret = ''
			switch (SetType){
				case 'department':
					ret = '部门'
					break;
				case 'stuff':
					ret = '人员'
					break;
			}
			return ret;
		}
  },
	{
		field: 'SetTimeType',
		headerName: '周期',
		editable: false,
		width: 100,
		cellClass: 't-c',
		valueFormatter: ({ data: { SetTimeType } }) => {
			// 分配时间段.  周: week, 月：month， 季度: season
			let ret = ''
			switch (SetTimeType){
				case 'week':
					ret = '周'
					break;
				case 'month':
					ret = '月'
					break;
				case 'season':
					ret = '季度'
					break;
			}
			return ret;
		}
	},
  {
    field: 'AssignName',
    headerName: '人员名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'DeptName',
    headerName: '销售部门',
    editable: false,
    cellClass: 't-c'
  },
	{
		field: 'StartTime',
		headerName: '任务开始时间',
		editable: false,
		width: 150,
		cellClass: 't-c'
	},
	{
		field: 'EndTime',
		headerName: '任务结束时间',
		editable: false,
		width: 150,
		cellClass: 't-c'
	},
	{
		field: 'IsCompleted',
		headerName: '完成',
		width: 150,
		editable: false,
		cellClass: 't-c',
		valueFormatter: ({ data: { IsCompleted } }) => {
			return IsCompleted ? '已完成': '未完成';
		}
	},
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
const operation = [
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]

const typeMap = [
  {key: 'taskType', value: '任务总完成度'},
  {key: 'taskTotal', value: '任务总数'}
]

const taskAllocationEditColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 55,
    editable: false,
    cellClass: 't-c',
	  valueFormatter: ({ node: { rowIndex } }) => {
		  return rowIndex + 1;
	  }
  },

  {
    field: 'TaskName',
    headerName: '任务名称',
    editable: false,
    width: 90,
    cellClass: 't-l'
  },
  {
    field: 'TaskType',
    headerName: '任务类型',
    editable: false,
    width: 75,
    cellClass: 't-c',
	  valueFormatter({ data: { TaskType } }) {
		  let ret = ''
		  switch (TaskType) {
			  case 1:
				  ret = '新增'
				  break;
			  case 2:
				  ret = '修改'
				  break;
			  case 3:
				  ret = '跟进'
				  break;
			  case 4:
				  ret = '购买'
				  break;
			  case 5:
				  ret = '其他'
				  break;
		  }
		  return  ret
	  }
  },
  {
    field: 'TaskDescription',
    headerName: '任务说明',
    editable: false,
    cellClass: 't-l'
  },
  {
    field: 'Score',
    headerName: '单个分值',
    editable: false,
    width: 80,
    cellClass: 't-r'
  },
  {
    field: 'TaskQty',
    headerName: '任务数量',
    editable: true,
    width: 80,
    cellClass: 't-r'
  },
  {
    field: 'TargetScore',
    headerName: '任务总分数',
    editable: false,
    width: 100,
    cellClass: 't-r'
  },
  {
    field: 'TargetAmount',
    headerName: '目标额度',
    editable: true,
    width: 100,
    cellClass: 't-r'
  }
]


export {
  operation,
  defaultColumns,
  taskAllocationEditColumns,
  typeMap
}

 <template>
  <div class="c-task-allocation-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section>
        <el-form ref="editItems" label-width="130px">
          <el-form-item label="分配方式: ">
            <el-radio-group v-model="form.SetType">
              <el-radio :label="'stuff'" :disabled="isEdit" name="type"
                >按人员分配</el-radio
              >
              <el-radio :label="'department'" :disabled="isEdit" name="type"
                >按部门分配</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="分配时间段: ">
            <el-radio-group v-model="form.SetTimeType" @change="onDateChange">
              <el-radio :label="'week'" :disabled="isEdit" name="type"
                >周</el-radio
              >
              <el-radio :label="'month'" :disabled="isEdit" name="type"
                >月</el-radio
              >
              <el-radio :label="'season'" :disabled="isEdit" name="type"
                >季度</el-radio
              >
            </el-radio-group>
            <!-- <el-input-number v-model="num" @change="handleChange" :min="1" :max="6"  label="天数">天数</el-input-number> -->
          </el-form-item>

          <el-form-item
            :label="form.SetType === 'stuff' ? '人员名称' : '部门名称'"
          >
          
            <el-select
              :disabled="isEdit"
              v-if="form.SetType === 'stuff'"
              multiple
              v-model="form.AssignToID"
              placeholder="人员名称"
              style="width: 100%"
              @change="onChangeAssignToID"
            >
              <el-option
                v-for="item in systemUser"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
            <el-select
              :disabled="isEdit"
              v-else
              v-model="form.DeptId"
              placeholder="部门名称"
              style="width: 100%"
            >
              <el-option
                v-for="item in systemDepart"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择时间: ">
            <el-date-picker
              :disabled="isEdit"
              v-model="form.SelectedDate"
              placeholder="选择日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              type="date"
              @change="onDateChange"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="已分配任务总数: ">
            <span>{{ editItems.taskTotalNum }}</span>
          </el-form-item>
          <el-form-item label="已分配任务总分数: ">
            <span>{{ editItems.taskTotalScore }}</span>
          </el-form-item>
        </el-form>
      </section>

      <section class="m-margin--top-10">
        <el-tabs v-model="tabName" type="border-card" @tab-click="onTabClick">
          <el-tab-pane
            v-for="item in form.TaskDetails"
            :key="item.key"
            :name="item.dom"
          >
            <div slot="label" class="p-task-allocation_tal-label">
              <p class="top">
                {{ new Date(item.StartTime).toLocaleDateString() }}
              </p>
              <p class="bottom">
                {{ new Date(item.EndTime).toLocaleDateString() }}
              </p>
            </div>
            <div :class="item.dom" style="height: 400px">
              <grid
                :id="item.dom"
                :ref="item.dom"
                :columns="taskAllocationEditColumns"
                :datas="item.TaskSetInfos"
                :suppressDragLeaveHidesColumns="true"
                :suppressPaginationPanel="false"
                headerHeight="35"
                type="edit"
              >
              </grid>
            </div>
          </el-tab-pane>
        </el-tabs>
      </section>

      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { taskAllocationEditColumns } from "./config";
import { getTaskConfigList, GetTasksetInfo } from "@/api/config";
import { taskListMap, editDataMap } from "./dataMap";
import { getWeek, getMonth, getQuarter, formatDate } from "@/utils/tool";

export default {
  name: "TaskAllocationEditView",
  directives: {
    elDragDialog,
  },
  computed: {
    systemUser() {
      const data = this.$store.state.common.SystemUser;
      return data.map((item) => {
        return {
          key: item.UserID,
          value: item.UserName,
        };
      });
    },
    systemDepart() {
      const data = this.$store.state.common.SystemDepart;
      return data.map((item) => {
        return {
          key: item.DeptID,
          value: item.DeptName,
        };
      });
    },
  },
  data() {
    return {
      num: 1,
      visible: false,
      title: "任务分配",
      taskAllocationEditColumns: taskAllocationEditColumns,
      itemSource: [],
      form: {
        TSID: 0,
        SetType: "stuff",
        SetTimeType: "week",
        AssignToID: [],
        DeptId: "",
        SelectedDate: new Date(),
        TaskDetails: [],
      },
      taskList: [],
      editItems: {
        id: 0,
        isEdit: 0,
        setType: "stuff",
        dateType: "month",
        userName: "",
        tsid: 0,
        selectDate: new Date(),
        taskTotalScore: "",
        taskTotalNum: "",
        taskList: [],
      },
      dateTypeOption: [], // tab栏数据
      tabName: "", // tab选中的名字
      isEdit: false,
      listItems: {}, // 表格数据
    };
  },
  methods: {
    onChangeAssignToID(e) {
      console.log("onChangeAssignToID e is ", e);
    },
    listMap({ TaskDetails }) {
      this.form.TaskDetails = TaskDetails.map((a) => {
        const { TaskSetInfos } = a;
        const ls = [];
        for (let [index, elem] of TaskSetInfos.entries()) {
          for (let [i, e] of this.taskList.entries()) {
            if (elem.TaskId === e.TaskID) {
              ls.push({ ...e, ...elem });
            }
          }
        }
        return { ...a, TaskSetInfos: ls };
      });
    },
    async getInfo() {
      const { TSID, SelectedDate, SetTimeType, AssignToID } = this.form;
      const data = await GetTasksetInfo({
        TSID,
        SelectedDate,
        SetTimeType,
        AssignToID,
      });

      if (!Array.isArray(data.AssignToID)) {
        data.AssignToID = [];
      }

      this.form = data;
      this.listMap(data);
    },
    async getListInfo() {
      const { TSID, SelectedDate, SetTimeType, AssignToID } = this.form;
      const data = await GetTasksetInfo({
        TSID,
        SelectedDate,
        SetTimeType,
        AssignToID,
      });
      this.listMap(data);
    },
    // 获取任务数据
    async getTaskConfigList() {
      const data = await getTaskConfigList();
      const { Items } = data;
      this.taskList = Items;
    },

    async onOpen(data) {

      this.isEdit = data !== null;
      this.title = data !== null ? "编辑任务分配" : "新增任务分配";

      if (data == null) {

        this.form.TSID = 0;
        this.form.AssignToID = [];
      }

      this.form.SetTimeType = "week";
      this.form = { ...this.form, ...data };

      if (!Array.isArray(this.form.AssignToID)) {
        this.form.AssignToID = [];
      }

      await this.getTaskConfigList();
      await this.getInfo();
      this.visible = true;
    },

    // 编辑处理选择数据
    setTaskList(list) {
      const { dateTypeOption } = this;
      let selectTaskList = list.map((item) => {
        return {
          id: item.id,
          taskTotal: item.taskTotal,
          taskTotalScore: item.taskTotalScore,
          targetLine: item.targetLine,
          startTime: formatDate(item.startTime, "yyyy-MM-dd"),
          endTime: formatDate(item.endTime, "yyyy-MM-dd"),
        };
      });
      dateTypeOption.forEach((line) => {
        const selectItems = [];
        selectTaskList.forEach((item) => {
          if (item.startTime === line.startDate) {
            selectItems.push(item);
            line["selectLines"] = selectItems;
          }
        });
      });
    },
    selectTaskList() {
      const { dateTypeOption, listItems } = this;
      dateTypeOption.forEach((item) => {
        if (item.selectLines.length !== 0) {
          listItems[item.dom].forEach((line) => {
            const selectLine = item.selectLines.find(
              (key) => key.id === line.id
            );
            if (selectLine) {
              line.taskTotal = selectLine.taskTotal;
              line.taskTotalScore = selectLine.taskTotalScore;
              line.targetLine = selectLine.targetLine;
              line.isSelect = true;
            }
          });
        }
        const dom = this.$refs[item.dom][0];
        dom.forEachNode((node) => {
          const { data } = node;
          if (data.isSelect) {
            return node.setSelected(true);
          } else {
            return node.setSelected(false);
          }
        });
      });
    },

    // 切换方式
    async onDateChange() {
      await this.getListInfo();
    },

    // 点击tab 触发
    onTabClick(e) {
      console.log(this.tabName);
    },

    async onSave() {
      const { AssignToID, DeptId, TaskDetails } = this.form;

      let err = "";
      if (!AssignToID && !DeptId) {

        this.$message.warning("用户或部门不能为空");
        return;
      }
      TaskDetails.forEach((item) => {
        item.TaskSetInfos.forEach((element) => {
          if (element.TaskQty < 0) {
            err = "任务数量不能小于0";
          }
        });
      });
      if (err) {
        this.$message.warning(err);
        return;
      }
      this.$emit("onEditConfig", this.form);
      this.visible = false;
    },

    handleDrag() {},
  },
  created() {
  },
};
</script>
<style lang="scss">
.c-task-allocation-view-dialog {
  .el-dialog {
    min-width: 600px;
  }

  .el-form-item {
    margin-bottom: 8px;
  }

  .el-radio-group {
    line-height: 28px !important;
  }

  .el-dialog__body {
    padding: 5px 20px 10px !important;
  }

  .p-task-allocation_tal-label {
    .top,
    .bottom {
      padding: 0;
      height: 16px;
      font-size: 12px;
      line-height: 19px;
    }
  }

  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }
}
</style>


<!--标签维护-->
<template>
  <div class="m-grid p-task-allocation">
    <section class="m-grid-search">
      <znl-input
        v-model="listQuery.DeptId"
        :select-options="systemDepart"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="搜索部门"
        width="150px">
      </znl-input>
      <znl-input
        v-model="listQuery.AssignToID"
        border
        :select-options="systemUser"
        form-type="select"
        class="m-margin--right-10"
        clearable
        placeholder="搜索人员"
        type="text"
        width="150px">
      </znl-input>
      <znl-input
          v-model="listQuery.IsCompleted"
          border
          :select-options="IsCompleteds"
          form-type="select"
          class="m-margin--right-10"
          clearable
          placeholder="完成状态"
          type="text"
          width="150px">
      </znl-input>

      <el-radio-group v-model="listQuery.SetTimeType" @change="onTypeChange">
        <el-radio :label="''">全部</el-radio>
        <el-radio :label="'week'">周</el-radio>
        <el-radio :label="'month'">月</el-radio>
        <el-radio :label="'season'">季</el-radio>
      </el-radio-group>

      <el-button class='m-grid-btn' type="primary" @click="onSearch()">查询</el-button>
      <el-button class='m-grid-btn' type="primary" @click="onAdd" v-can="'config_task_allocation:allocation'">分配</el-button>
      <el-button class='m-grid-btn' type="primary" @click="onDelete" v-can="'config_task_allocation:delete'">删除</el-button>
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="true"
        :total-count="pagination.total"
        type="base">
      </grid>
    </section>


    <task-allocation-edit-view ref="editView" @onEditConfig="onEditConfig"></task-allocation-edit-view>
    <!--    <task-allocation-edit-view ref="editView" @getTaskAllocation="getTaskAllocation()"></task-allocation-edit-view>-->



  </div>
</template>

<script>
import {
  typeMap,
  defaultColumns,
  operation
} from "./modules/taskAllocation/config";
import TaskAllocationEditView from "./modules/taskAllocation/TaskAllocationEditView"
import {getTaskAllocationList, DeleteDispatchTaskSet, editTaskAllocation, getTaskAllocationInfo} from "@/api/config";

import {getWeek, getMonth, getQuarter, far} from '@/utils/tool'
import {formatDate} from "../../utils/tool";
import {deleteClient} from "@/api/client";

export default {
  name: "configTaskAllocation",
  computed: {
    systemUser() {
      const data = this.$store.state.common.SystemUser
      return data.map(item => {
        return {
          key: item.UserID,
          value: item.UserName
        }
      })
    },
    systemDepart() {
      const data = this.$store.state.common.SystemDepart
      return data.map(item => {
        return {
          key: item.DeptID,
          value: item.DeptName
        }
      })
    }
  },
  components: {
    TaskAllocationEditView
  },
  data() {
    return {
      columns: defaultColumns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0
      },
      listQuery: {
        DeptId: '',
        AssignToID: '',
        SetTimeType: '',
        IsCompleted: ''
      },
      searchData: {
        salesDepartment: 0,
        IsCompleted: false,
        userName: '',
        dateType: '',
        setType: '',
        selectDate: []
      },
      //周: week, 月：month， 季度: season
      typeOptions: [
         {key: '', value: '全部'},
        {key: 'week', value: '周'},
        {key: 'month', value: '月'},
        {key: 'season', value: '季'}
      ],
      IsCompleteds: [
        {key: '', value: '全部'},
        {key: true, value: '已完成'},
        {key: false, value: '未完成'}
      ],
      dateSelectList: [],
      listDataMap: {

      }
    }
  },
  methods: {
    async onInit() {
      await this.onSearch()
    },
    async onSearch(page = 1) {
      const {pagination, listQuery} = this
      const sendData = {
        ...listQuery,
        Page: page,
        Limit: pagination.size
      }
      const data = await getTaskAllocationList(sendData) // 请求接口

      pagination.page = data.Page
      pagination.total = data.Total

      this.itemSource = data.Items || []
    },

    onTypeChange() {
      this.onInit()
    },
    // 初始化列头
    setColumns() {
      let configColumns = defaultColumns.map(column => Object.assign({}, column))
      // 设置操作
      const self = this
      const OriginColumn = configColumns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons(),
        };
      }
      this.columns = configColumns; // defaultColumns.map(column => Object.assign({}, column))
    },
    setButtons() {
      let btns = []
      let self = this
      btns.push({
        text: "分配",
        click(params) {
          self.onOpenEdit(params.data);
        },
      });
      return btns
    },

    // 操作
    onAdd() {

      this.isEdit = false
      this.$refs.editView.onOpen(null)
    },
    async onDelete() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;
      if (length === 0) {
        this.$message.warning('请选择数据');
        return false;
      }
      this.$confirm("此操作将永久删除该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const TSIDs = selectRow.map(a => a.TSID)
        const data = { TSIDs }
        await DeleteDispatchTaskSet(data)
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        await this.onSearch(this.pagination.page);
      })
      .catch(() => {
      });

    },
    async onOpenEdit(data) {
      const { TSID,AssignToID } = data
      await this.$refs.editView.onOpen({ TSID ,AssignToID})
    },
    // 获取详情列表
    async getTaskAllocation(data) {

      return await getTaskAllocationInfo(data)
    },

    async onEditConfig(data) {
      await editTaskAllocation(data)
      let message = '任务分配保存成功'
      this.$message.success(message)
      this.onSearch(this.pagination.page)
    }
  },
  created() {

    this.onInit()

  },
  mounted() {
    this.setColumns()
  },
  watch: {}
}
</script>

<style lang="scss">
.p-task-allocation {
  .el-radio-group {
    line-height: 40px;
    margin-right: 10px;
  }

}
</style>


